<template>
  <el-dialog 
    title="发货"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
        <el-form-item prop="" label="快递">
          <el-input v-model="expressageType" placeholder="请选择" disabled></el-input>
        </el-form-item>
        <el-form-item prop="delyNum" label="快递单号" verify>
          <el-input v-model="formData.delyNum" placeholder="请输入快递单号" :maxlength="50"></el-input>
        </el-form-item>
      </el-form>
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {numberSend} from '@/api/order'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      expressageType: '中通快递',
      loading: false,
      formData: {},
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        console.log('formData:', this.formData)
        if(!valid) return false
        this.loading = true
        const params = {
          orderId: this.itemId,
          delyNum: this.formData.delyNum,
        }
        try{
          const res = await numberSend(params)
          this.loading = false
          this.$message.success('操作成功')
          this.$emit('reload')
          this.handleClose()
        }catch(e){
          this.loading = false
        }
      })
        
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    }
  },
}
</script>

<style>

</style>